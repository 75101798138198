import React, {useState, useEffect} from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import '../../assets/css/custom.css'
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { getDigitalSignByCategory, addDigitalSign, deleteDigitalSign, updateDigitalSign } from "api/digitalsignsandcategories";
import { toast } from "react-toastify";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import signtextdata from '../../assets/json/patextdata.json';
import { uploadMedia } from "api/uploadmedia";
import { RadioButton } from "primereact/radiobutton";
import { Image } from "primereact/image";
import { ProgressBar } from "primereact/progressbar";
import NotAvailableIcon from "components/NotAvailableIcons/NotAvailableIcon";
import { Tooltip } from 'primereact/tooltip';
import { useDispatch } from 'react-redux';
import {getMasterDigitalSignByCategory} from "api/masterdigitalsignandcategories";
import {addMasterDigitalSign} from "api/masterdigitalsignandcategories";
import {updateMasterDigitalSign} from "api/masterdigitalsignandcategories";
import {deleteMasterDigitalSign} from "api/masterdigitalsignandcategories";
import {uploadMasterTemplateMedia} from "api/uploadmedia";
const DigitalSignsList = (props) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [digitalSignName, setDigitalSignName] = useState("");
    const [price, setPrice] = useState("");
    const [digitalSigns, setDigitalSigns] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [signId, setSignId] = useState(null);
    const [fileURI, setFileURI] = useState(null);
    const [thumbURI, setThumbURI] = useState(null);
    const [videoURI, setVideoURI] = useState(null);
    const [mediaType, setMediaType] = useState(101);
    const [isEnabled, setIsEnabled] = useState(true);
    const [uploadProgressImage, setUploadProgressImage] = useState(0);
    const [uploadProgressVideo, setUploadProgressVideo] = useState(0);
    const [uploadProgressThumbnail, setUploadProgressThumbnail] = useState(0);
    const user = useSelector((state) => state.user);
    const club = useSelector((state) => state.club);
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        console.log("AAAA", props)
        if(!isEdit && props.perspective === "club") {
            getDigitalSignByCategory(id, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    setDigitalSigns(response.data.club_digital_sign);
                }
            })
            .catch((error) => {
                if(error.response.status === 400) {
                localStorage.clear();
                    dispatch({
                        type: 'LOGOUT',
                        payload: {
                        },
                    });
                    history.push('/login');
                }
            });
        } else if(props.perspective === "master"){
            getMasterDigitalSignByCategory(id, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    setDigitalSigns(response.data.digital_signs);
                }
            })
            .catch((error) => {
                if(error.response.status === 400) {
                    localStorage.clear();
                    dispatch({
                      type: 'LOGOUT',
                      payload: {
                      },
                    });
                    history.push('/login');
                }
            });
        }
    },[loading]);
    useEffect(() => {
        enableDisableSave();
    },[fileURI,videoURI, thumbURI]);
    const header = (sign) => (
        <div className="container" style={{backgroundImage: `url(${sign?.thumb_url})`,height:'270px', width:'250px', backgroundSize:'cover',backgroundRepeat:'no-repeat', backgroundPosition:'center', marginTop:'12.8px'}}></div>
    );
    const handleHideDialog = () => {
        setMediaType(101);
        setFileURI(null);
        setVideoURI(null);
        setThumbURI(null);
        setDigitalSignName("");
        setPrice("");
        setErrors({});
        setVisible(false);
    }

    const handleSignUpdate = () => {
        setLoading(true);
        const updateSignData = { 
            "id": signId,
            "club_id": club.club_id,
            "title": digitalSignName,
            "image_url": fileURI || videoURI,
            "thumb_url":(mediaType === 101) ? fileURI : thumbURI,
            "price": parseInt(price),
            "of_type": id,
            "currency": "$",
            "request_type": mediaType
        }
        if(props.perspective === "master") {
            updateMasterDigitalSign(updateSignData, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    toast.success(signtextdata.digitalsign.mediaupdatemsg);
                    setDigitalSignName("");
                    setPrice("");
                    setVideoURI(null);
                    setThumbURI(null);
                    setVisible(false);
                    setLoading(false);
                    setIsEdit(false);
                }
            }).catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                      error: error
                    },
                });
                history.push("/something-went-wrong");
            })
        } else {
            updateDigitalSign(updateSignData, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    toast.success(signtextdata.digitalsign.mediaupdatemsg);
                    setDigitalSignName("");
                    setPrice("");
                    setVideoURI(null);
                    setThumbURI(null);
                    setVisible(false);
                    setLoading(false);
                    setIsEdit(false);
                }
            })
            .catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                    error: error
                    },
                });
                history.push("/something-went-wrong");
            })
        }
    }

    const handleSignSave = () => {
        if(validateForm()) {
            const signData = { 
                "club_id": club.club_id,
                "title": digitalSignName,
                "image_url": fileURI || videoURI,
                "thumb_url": (mediaType === 101) ? fileURI : thumbURI,
                "price": parseInt(price),
                "of_type": id,
                "currency": "$",
                "request_type": mediaType
            }
            if(props.perspective === "master") {
                addMasterDigitalSign(signData, user?.authtoken)
                .then((response) => {
                    if(response.status === 200) {
                        setIsEdit(false);
                        setLoading(!loading);
                        setDigitalSignName("");
                        setPrice("");
                        setVideoURI(null);
                        setThumbURI(null);
                        setVisible(false);
                        toast.success(signtextdata.digitalsign.mediaaddmsg);
                    }
                }).catch((error) => {
                    dispatch({
                        type: 'IS_ERROR',
                        payload: {
                          error: error
                        },
                    });
                    history.push("/something-went-wrong");
                });
            } else {
                addDigitalSign(signData, user?.authtoken)
                .then((response) => {
                    if(response.status === 200) {
                        setIsEdit(false);
                        setLoading(!loading);
                        setDigitalSignName("");
                        setPrice("");
                        setVideoURI(null);
                        setThumbURI(null);
                        setVisible(false);
                        toast.success(signtextdata.digitalsign.mediaaddmsg);
                    }
                }).catch((error) => {
                    dispatch({
                        type: 'IS_ERROR',
                        payload: {
                        error: error
                        },
                    });
                    history.push("/something-went-wrong");
                });
            }
        }
    }
    const enableDisableSave = () => {
        if (mediaType === 101) {
            return setIsEnabled(fileURI === null);
        } else if (mediaType === 102) {
            return setIsEnabled(videoURI === null || thumbURI === null);
        }
        return true;
    };
    

    const footerContent = (
        <div className="d-flex justify-content-center align-items-center">
            <Button label={signtextdata.digitalsign.cancel} icon="pi pi-times" onClick={() => handleHideDialog()} className="p-button-text cancel-button" />
            <Button label={isEdit ? signtextdata.digitalsign.update : signtextdata.digitalsign.save} icon="pi pi-check" autoFocus className="normal-button" onClick={isEdit ? handleSignUpdate : handleSignSave} disabled={isEnabled}/>
        </div>
    );
    const handleDigitalSignName = (fieldName,value) => {
        setDigitalSignName(value);
        validateField(fieldName, value);
    }
    const openDigitalSignDialog = () => {
        setIsEdit(false);
        setVisible(true);
    }
    const handlePrice = (fieldName,value) => {
        setPrice(value);
        validateField(fieldName, value);
    }
    const handleMediaType = (fieldName, value) => {
        if(parseInt(value) === 101) {
          delete errors.video;
          delete errors.thumbnail;
          setErrors(errors);
          setVideoURI(null);
          setThumbURI(null);  
        } else {
            delete errors.image;
            setErrors(errors);
            setFileURI(null);
        }
        setMediaType(parseInt(value));
        validateField(fieldName, value);
    }
    const footer = (sign) => {
        return(
            <>
                <Button disabled={sign.request_type === 0 ? true : false} label={signtextdata.digitalsign.edit} icon="pi pi-pencil" className="normal-button" onClick={(event) => handleEditDigitalSign(event,sign)}/>
                <Button label={signtextdata.digitalsign.delete} severity="danger" icon="pi pi-trash" style={{ marginLeft: '0.5em' }} onClick={(event)=>confirm(event,sign)} />
            </>
        )
    };
    const handleAccept = (id) => {
        if(props.perspective === "master") {
            deleteMasterDigitalSign(id, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    toast.success("Digital media deleted successfuly!");
                    setLoading(!loading);
                }
            }).catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                    error: error
                    },
                });
                history.push("/something-went-wrong");
            })
        } else {
            deleteDigitalSign(id, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    toast.success(response.data.msg);
                    setLoading(!loading);
                }
            }).catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                    error: error
                    },
                });
                history.push("/something-went-wrong");
            })
        }
    }
    const handleReject = () => {
    
    }
    const confirm = (event,sign) => {
        confirmDialog({
            message: signtextdata.digitalsign.cnfmdialogmsg,
            header: signtextdata.digitalsign.cnfmdialogheader,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            draggable: false,
            closeOnEscape: true,
            accept: () => handleAccept(sign.id), 
            reject: () => handleReject(category.id)
        });
    };
    const handleEditDigitalSign = (event, sign) => {
        setIsEdit(true);
        setLoading(false);
        setVisible(true);
        setDigitalSignName(sign.title);
        setPrice(sign.price);
        setThumbURI(sign.thumb_url);
        setMediaType(sign.request_type);
        if(sign.request_type === 101) {
            setFileURI(sign.image_url);
        } else {
            setVideoURI(sign.image_url);
        }       
        setSignId(sign.id);
    }
    const validateField = (fieldName,value) => {
        let error = "";
        if(!value) {
          error = `${fieldName} is required`;
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          [fieldName]: error
        }));
      
        return error;
    };
    const validateForm = () => {
        const newErrors = {};
        if (!digitalSignName) {
          newErrors.name = signtextdata.digitalsign.namerrmsg;
        }
        if (!price) {
          newErrors.price = signtextdata.digitalsign.pricerrmsg;
        }
        if(!videoURI && mediaType === 2) {
            newErrors.video = signtextdata.digitalsign.videourierror;
        }
        if(!thumbURI && mediaType === 2) {
            newErrors.thumbnail = signtextdata.digitalsign.thumburierror;
        }
        if(!fileURI && mediaType === 1) {
            newErrors.image = signtextdata.digitalsign.imageurierror;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    };
    const onUpload = (image, event) => {
        const formData = new FormData();
        formData.append('file', event.files[0]);
        // Create a new XMLHttpRequest
        const xhr = new XMLHttpRequest();
        // Add event listeners for progress tracking
        xhr.upload.addEventListener('progress', (e) => {
            const progress = Math.round((e.loaded * 100) / e.total);
            setUploadProgressImage(progress);
        });
        if(props.perspective === "master") {
            uploadMasterTemplateMedia(formData, user?.authtoken, (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgressImage(progress);
            }).then((response) => {
                if(response.status === 200) {
                    validateField(image, response.data.url);
                    setFileURI(response.data.url);
                    setUploadProgressImage(0);
                }
            })
            .catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                    error: error
                    },
                });
                history.push("/something-went-wrong");
            })
        } else {
            uploadMedia(formData, club.club_id, user?.authtoken, (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgressImage(progress);
            })
            .then((response) => {
                if(response.status === 200) {
                    validateField(image, response.data.url);
                    setFileURI(response.data.url);
                    setUploadProgressImage(0);
                }
            })
            .catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                    error: error
                    },
                });
                history.push("/something-went-wrong");
            })
        }
    };
    const onUploadVideo = (video, event) => {
        const formData = new FormData();
        formData.append('file', event.files[0]);
        // Create a new XMLHttpRequest
        const xhr = new XMLHttpRequest();
        // Add event listeners for progress tracking
        xhr.upload.addEventListener('progress', (e) => {
            const progress = Math.round((e.loaded * 100) / e.total);
            setUploadProgressVideo(progress);
        });
        uploadMedia(formData, club.club_id, user?.authtoken, (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgressVideo(progress);
        })
        .then((response) => {
            if(response.status === 200) {
                validateField(video, response.data.url);
                setVideoURI(response.data.url);
                setUploadProgressVideo(0);
            }
        })
        .catch((error) => {
            dispatch({
                type: 'IS_ERROR',
                payload: {
                  error: error
                },
            });
            history.push("/something-went-wrong");
        })
    }
    const onUploadThumbnailUrl = (thumbnail, event) => {
        const formData = new FormData();
        formData.append('file', event.files[0]);
        // Create a new XMLHttpRequest
        const xhr = new XMLHttpRequest();
        // Add event listeners for progress tracking
        xhr.upload.addEventListener('progress', (e) => {
            const progress = Math.round((e.loaded * 100) / e.total);
            setUploadProgressThumbnail(progress);
        });
        uploadMedia(formData, club.club_id, user?.authtoken, (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgressThumbnail(progress);
        })
        .then((response) => {
            if(response.status === 200) {
                validateField(thumbnail, response.data.url);
                setThumbURI(response.data.url);
                setUploadProgressThumbnail(0);
            }
        })
        .catch((error) => {
            dispatch({
                type: 'IS_ERROR',
                payload: {
                  error: error
                },
            });
            history.push("/something-went-wrong");
        })
    }
    const cardTitle = (sign) => {
        return(
            <>
                <Tooltip mouseTrack={true} target=".d-inline-block" />
                <span class="d-inline-block text-truncate" style={{maxWidth: '200px'}} data-pr-position="top" data-pr-tooltip={sign.title}>{sign.title}</span>
            </>
        )
    }

    return(
        <>
            <Dialog header={isEdit ? signtextdata.digitalsign.editSign : signtextdata.digitalsign.addsign} visible={visible} style={{ width: '30rem' }} draggable={false} onHide={() => handleHideDialog()} footer={footerContent}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-group" style={{width:"100%"}}>
                            <label>{signtextdata.digitalsign.name}<span className="redStar">*</span></label>
                            <InputText 
                                className={`form-control ${errors.name ? "is-invalid" : ""} gray-placeholder`}
                                value={digitalSignName}
                                name="digital_sign_name"
                                onChange={(event) => handleDigitalSignName('name',event.target.value)}
                                style={{ display: 'flex'}} 
                                placeholder={signtextdata.digitalsign.entername}
                            />
                            {errors.name && (
                                <div className="invalid-feedback">{errors.name}</div>
                            )}
                        </div>
                        <div className="form-group" style={{width:"100%"}}>
                            <label>{signtextdata.digitalsign.price}<span className="redStar">*</span></label>
                                <InputText 
                                  className={`form-control ${errors.price ? "is-invalid" : ""}`}
                                  value={price}
                                  name="price"
                                  onChange={(event) => handlePrice('price',event.target.value)}
                                  style={{display:'flex'}}
                                  maxLength={3}
                                  keyfilter="int" 
                                  placeholder={signtextdata.digitalsign.enterprice}
                                />
                                {errors.price && (
                                    <div className="invalid-feedback">{errors.price}</div>
                                )}
                        </div>
                        <div className="form-group" style={{width:"100%"}}>
                            <label>{signtextdata.digitalsign.media_type}<span className="text-danger">*</span></label>
                            <div className={`card d-flex justify-content-center mb-0 form-control`}>
                                <div className="d-flex gap-3 height-38">
                                    <div className="d-flex align-items-center ml-10">
                                        <RadioButton className="p-checkbox" inputId={signtextdata.digitalsign.manager} name="role" value="101" onChange={(e) => handleMediaType('mediatype', e.value)} checked={mediaType === 101} />
                                        <label htmlFor={signtextdata.digitalsign.manager} className="ml-2 mb-0">{signtextdata.digitalsign.image}</label>
                                    </div>
                                    <div className="d-flex align-items-center ml-10">
                                        <RadioButton className="p-checkbox" inputId={signtextdata.digitalsign.server} name="role" value="102" onChange={(e) => handleMediaType('mediatype', e.value)} checked={mediaType === 102} />
                                        <label htmlFor={signtextdata.digitalsign.server} className="ml-2 mb-0">{signtextdata.digitalsign.video}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            mediaType === 101 ? 
                            <div className="form-group" style={{width:"100%"}}>
                                <label>{signtextdata.digitalsign.image}<span className="redStar">*</span></label>
                                <div className="card flex justify-content-center" style={{ border: (errors.image !== undefined && fileURI === null) ? "1px solid #DD3B4A" : "", marginBottom:"15px" }}>
                                    <FileUpload mode="basic" name="file[]" accept="image/*" maxFileSize={5000000} onSelect={(event) => onUpload("image", event)} chooseLabel={signtextdata.digitalsign.browse} />
                                </div>
                                {
                                    uploadProgressImage > 0 && <div className="flex justify-content-center">
                                        <ProgressBar style={{height:"10px"}} value={uploadProgressImage} />
                                        <div className="flex justify-content-center" style={{fontSize:'12px', display:"flex"}}>
                                        {
                                            uploadProgressImage + '%'
                                        }
                                        </div>
                                    </div>
                                }
                                {
                                    fileURI && <div className="card flex justify-content-center">
                                        <Image src={fileURI} zoomSrc={fileURI} alt={signtextdata.digitalsign.image} width="80" height="60" preview />
                                    </div>
                                }
                                {errors.image && (
                                  <div style={{color:"#DD3B4A", fontSize:"80%", marginTop:"-25px"}}>{errors.image}</div>
                                )}
                            </div>
                            :
                            <>
                                <div className="form-group" style={{width:"100%"}}>
                                    <label>{signtextdata.digitalsign.video}<span className="redStar">*</span></label>
                                    <div className="card flex justify-content-center" style={{ border: (errors.video !== undefined && videoURI === null) ? "1px solid #DD3B4A" : "", marginBottom:"15px" }}>
                                        <FileUpload mode="basic" name="file[]" accept="video/*" onSelect={(event) => onUploadVideo("video", event)} chooseLabel={signtextdata.digitalsign.browse} />
                                    </div>
                                    {
                                        uploadProgressVideo > 0 && <div className="flex justify-content-center">
                                            <ProgressBar style={{height:"10px"}} value={uploadProgressVideo} />
                                            <div className="flex justify-content-center" style={{fontSize:'12px', display:"flex"}}>
                                            {
                                                uploadProgressVideo + '%'
                                            }
                                            </div>
                                        </div>
                                    }
                                    {
                                        videoURI && <div className="card flex justify-content-center embed-responsive embed-responsive-16by9" style={{height:'65px', width:"45%", marginBottom:'5px'}}>
                                            <video id="video_preview" src={videoURI} type="video/mp4" controls></video>
                                        </div>
                                    }
                                    {errors.video && (
                                        <div style={{color:"#DD3B4A", fontSize:"80%", marginTop:"-25px"}}>{errors.video}</div>
                                    )}
                                </div>
                                <div className="form-group" style={{width:"100%"}}>
                                    <label>{signtextdata.digitalsign.thumb_image}<span className="redStar">*</span></label>
                                    <div className="card flex justify-content-center" style={{ border: (errors.thumbnail !== undefined && thumbURI === null) ? "1px solid #DD3B4A" : "", marginBottom:"15px" }}>
                                        <FileUpload mode="basic" name="thumbImage[]" accept="image/*" maxFileSize={5000000} onSelect={(event) => onUploadThumbnailUrl("thumbnail", event)} chooseLabel={signtextdata.digitalsign.browse} />
                                    </div>
                                    {
                                        uploadProgressThumbnail > 0 && <div className="flex justify-content-center">
                                            <ProgressBar style={{height:"10px"}} value={uploadProgressThumbnail} />
                                            <div className="flex justify-content-center" style={{fontSize:'12px', display:"flex"}}>
                                            {
                                                uploadProgressThumbnail + '%'
                                            }
                                            </div>
                                        </div>
                                    }
                                    {
                                        thumbURI && <div className="card flex justify-content-center">
                                            <Image src={thumbURI} zoomSrc={thumbURI} alt="Image" width="80" height="60" preview />
                                        </div>
                                    }
                                    {errors.thumbnail && (
                                        <div style={{color:"#DD3B4A", fontSize:"80%", marginTop:"-25px"}}>{errors.thumbnail}</div>
                                    )}
                                </div>
                                
                            </>
                        }
                        
                    </div>
                </div>
            </Dialog>
            <ConfirmDialog />
            <div className="container-fluid">
                <div className="row" style={{ paddingBottom: "1%" }}>
                    <div className="col-6 text-left">
                        <Button label={signtextdata.digitalsign.back} icon="pi pi-angle-left" link style={{textDecoration:"none"}} onClick={() => history.go(-1)}/>
                    </div>
                    <div className="col-6 text-right buttonParent">
                        <Button label={signtextdata.digitalsign.addnewsign} icon="pi pi-plus" onClick={openDigitalSignDialog} className="normal-button btn-sm" />
                    </div>
                </div>
                <div className={`row ${digitalSigns === null ? `justify-content-center` : ``}`}>
                    {
                        digitalSigns !== null ? digitalSigns?.map((sign,index) => {
                            return(
                                <div className="col-md-3 mb-4" key={index}>
                                    <div className="card flex justify-content-center" >
                                        <Card title={()=>cardTitle(sign)} header={()=>header(sign)} footer={()=>footer(sign)}  style={{ textAlign: 'center' }}>
                                            
                                        </Card>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <NotAvailableIcon/>
                    }
                </div>
            </div>
        </>
    )
}
export default DigitalSignsList;
