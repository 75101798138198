import React, { useEffect } from 'react';
import logo from '../../assets/img/reactlogo.png';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";

const ErrorFallback = () => {
    const error = useSelector((state) => state.error);
    useEffect(() => {
        if(!error) {
            history.push("/dashboard");
        }
    }, []);
    const history = useHistory();
    const handleBackToDashboard = () => {
        history.push("/dashboard");
    }
    return (
        <div className="vh-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#3C3935', color: '#fff' }}>
            <div className="text-center" style={{ maxWidth: '420px', position: 'relative' }}>
                <div className="display-3" style={{ fontWeight: 'bold', position: 'relative', zIndex: 1 }}>
                    <img src={logo} className='image-fluid' />
                </div>
                <h2 style={{ fontWeight: 'bold', position: 'relative', zIndex: 1 }}>
                    Something went wrong !
                </h2>
                <h1 style={{ animation: 'bounce 1s infinite', marginBottom: 0 }}>😢</h1> {/* Animation added here */}
                <div classname="d-flex justify-content-center align-items-center">
                    <Button onClick={handleBackToDashboard} className="btn btn-lg btn-primary back-to-dashboard">
                        Back to Dashboard
                    </Button>
                </div>
            </div>
            {/* Inline CSS for animation */}
            <style>
                {`
                    @keyframes bounce {
                        0%, 20%, 50%, 80%, 100% {
                            transform: translateY(0);
                        }
                        40% {
                            transform: translateY(-30px);
                        }
                        60% {
                            transform: translateY(-15px);
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default ErrorFallback;
